import { render, staticRenderFns } from "./ZensocialIndex.vue?vue&type=template&id=2aec9ff4&scoped=true"
import script from "./ZensocialIndex.vue?vue&type=script&lang=js"
export * from "./ZensocialIndex.vue?vue&type=script&lang=js"
import style0 from "./ZensocialIndex.vue?vue&type=style&index=0&id=2aec9ff4&prod&lang=css"
import style1 from "./ZensocialIndex.vue?vue&type=style&index=1&id=2aec9ff4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aec9ff4",
  null
  
)

export default component.exports